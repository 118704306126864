import { Button, Form, Image, Input, InputNumber, Radio, Select, TreeSelect } from "antd";
import { useEffect, useState } from "react";
import { useGlobalState } from "../../components/global";
import UploadComponent from "../../components/UploadComponent";
import { PlusOutlined } from "@ant-design/icons";

declare var window: any;

export default function MenuForm({ form, item, tenantMeta }: any) {
    const [menu, setMenu] = useState<any>([]);
    const [fileList, setFileList]: any = useState([]);
    const { jsonRequest, currentTenant } = useGlobalState();
    const [checkedKeys, setCheckedKeys] = useState([]);
    // const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [data, setData] = useState<any>([]);

    const handleDropdownVisibleChange = (visible: boolean) => {
        setDropdownVisible(visible);
    };

    const handleUploadSuccess = (fileList: any) => {
        const images = fileList.map((e: any) => ({
            ...e,
            url: e.url?.startsWith('http') ? e.url : `${window.ATTACHMENT_URL}/api/oss/${e.url}`,
            objName: e.url?.startsWith('http') ? e.url : `${window.ATTACHMENT_URL}/api/oss/${e.url}`
        }));
        setFileList([images[images.length - 1]]);
        form.setFieldValue("icon", images[images.length - 1].url);
    }

    // const toggleExpand = () => {
    //     if (expandedKeys.length > 0) {
    //         setExpandedKeys([]);
    //     } else {
    //         const keys = getAllData(menu);
    //         setExpandedKeys(keys);
    //     }
    //     setDropdownVisible(true);
    // };

    const handleCheck = (value: any, node: any) => {
        setCheckedKeys(value);
        if (node.level) {
            form.setFieldValue("level", node.level + 1);
        } else form.setFieldValue("level", 0);
    };

    // 遍历获取所有数据
    function getAllData(data: any) {
        return data.flatMap((item: any) => {
            const key = item.children ? getAllData(item.children) : [];
            return [...key, item.value];
        });
    }

    // 遍历将数据转换为树形结构
    function transfromData(data: any, value: string) {
        // 菜单类型选择button，则只能选择类型为menu，选择其他则在menu和catalogue中选择，其余禁用
        return data.map((item: any) => {
            const { name, id, children, menuType, level } = item;
            const res = {
                label: name,
                value: id || name,
                key: id || name,
                children: [],
                level: level,
                menuTpye: menuType,
                disabled: checkMenuType(menuType, value, level)
            };
            if (children && children.length) {
                res.children = transfromData(children, value);
            }
            return res;
        });
    }

    const checkMenuType = (menuType: string, value: string, level: number): boolean => {
        if (!level) return true;
        if (value === 'button') {
            return menuType !== 'menu';
        }
        return menuType === 'button';
    };

    const getData = (menu: any, menuType: string) => {
        const result = [{ label: '暂无上级菜单', value: 0, key: 0, menuType: 'menu', level: 0, children: [] }, ...transfromData(menu, menuType)];
        setData(result);
    };

    const getMenuData = async () => {
        // 获取菜单下拉数据
        jsonRequest.post("/api/request",
            {
                path: `/api/v1/menu/tree`,
                method: "get",
                application_key: "zy:services::user-library",
                tenant_meta: currentTenant,
                data: {},
            })
            .then((resp: any) => resp.json())
            .then(async ({ data }: any) => {
                setMenu(data);
                getData(data, item.menuType);
            });
    };

    useEffect(() => {
        getMenuData();
        form.setFieldsValue(item);
        form.setFieldValue('applicationKey', item.tenantMeta);
    }, []);

    return (
        <Form form={form} size="small" name="menu" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} autoComplete="off">
            <Form.Item label="所属应用" name="applicationKey" rules={[{ required: true, message: '不能为空' }]}>
                <Select placeholder="请选择应用"
                    options={tenantMeta?.map((e: any) => ({ value: e.tenantMeta, label: e.name }))}
                />
            </Form.Item>
            <Form.Item label="菜单类型" name="menuType" rules={[{ required: true, message: '不能为空' }]}>
                <Radio.Group onChange={(e) => getData(menu, e.target.value)}>
                    <Radio value={"catalogue"}>目录</Radio>
                    <Radio value={"menu"}>菜单</Radio>
                    <Radio value={"button"}>按钮</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item label="上级菜单" name="parentId">
                {/* <div style={{ paddingBottom: '3%' }}>
                    <Button onClick={toggleExpand}>
                        {expandedKeys.length > 0 ? '折叠' : '展开'}
                    </Button>
                </div> */}
                <TreeSelect
                    treeData={data}
                    allowClear
                    placeholder="请选择上级菜单"
                    // treeExpandedKeys={expandedKeys}
                    value={checkedKeys}
                    onSelect={(value, node) => handleCheck(value, node)}
                    open={dropdownVisible}
                    onDropdownVisibleChange={handleDropdownVisibleChange}
                />
            </Form.Item>
            <Form.Item name="level" hidden rules={[{ required: true, message: '不能为空' }]}>
                <Input />
            </Form.Item>
            <Form.Item label="菜单名称" name="name" rules={[{ required: true, message: '不能为空' }]}>
                <Input placeholder="请输入菜单名称" />
            </Form.Item>
            <Form.Item label="请求地址" name="frontRoute" rules={[{ required: true, message: '不能为空' }]}>
                <Input placeholder="请输入请求地址" />
            </Form.Item>
            <Form.Item label="权限标识" name="code" rules={[{ required: true, message: '不能为空' }]}>
                <Input placeholder="请输入权限字符串" />
            </Form.Item>
            <Form.Item label="排序号" name="index" initialValue={0} rules={[{ required: true, message: '不能为空' }]}>
                <InputNumber min={0} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item label="图标" name="image">
                <UploadComponent path={[currentTenant, "menu"].join("/")}
                    listType="picture-card"
                    value={fileList}
                    showUploadList={false}
                    onChange={(fileList: any) => handleUploadSuccess(fileList)}>
                    {!!fileList[0] ?
                        <Image src={fileList[0].url} preview={false} />
                        : <div>
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>拖到这里</div>
                        </div>}
                </UploadComponent>
            </Form.Item>
            <Form.Item label="图标地址" name="icon">
                <Input disabled />
            </Form.Item>
            <Form.Item label="菜单状态" name="state" initialValue={true} rules={[{ required: true, message: '不能为空' }]}>
                <Radio.Group>
                    <Radio value={true}>启用</Radio>
                    <Radio value={false}>禁用</Radio>
                </Radio.Group>
            </Form.Item>
        </Form>
    )
}