import { Link, useLocation } from "react-router-dom";
import { Menu } from "antd";
import { UnorderedListOutlined } from "@ant-design/icons";

function SideMenu() {
  const location = useLocation()

  const pathName = location.pathname.split("/").slice(0, 3).join("/")
  const fullPathName = location.pathname

  return <Menu style={{ width: 256 }} defaultSelectedKeys={[pathName, fullPathName]} mode="inline">
    <Menu.ItemGroup key="user-library" title="权限管理">
      <Menu.Item key="/user-library/user-library" icon={<UnorderedListOutlined />}>
        <Link to="/user-library/user-library">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>用户库</span>
          </div>
        </Link>
      </Menu.Item>
      <Menu.Item key="/user-library/role" icon={<UnorderedListOutlined />}>
        <Link to="/user-library/role">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>角色管理</span>
          </div>
        </Link>
      </Menu.Item>
      <Menu.Item key="/user-library/menu" icon={<UnorderedListOutlined />}>
        <Link to="/user-library/menu">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>菜单管理</span>
          </div>
        </Link>
      </Menu.Item>
    </Menu.ItemGroup>
  </Menu>
}


export default SideMenu