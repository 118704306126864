import { message, Input, Button, Form, Switch } from "antd"
import { useEffect, useState } from "react"
import { useGlobalState } from "../../components/global"

export default function AIForm() {

    const { jsonRequest, currentTenant } = useGlobalState()

    const [form] = Form.useForm()

    useEffect(() => {
        handleLoadData(currentTenant!)
    }, [currentTenant])

    const handleLoadData = async (currentTenant: string) => {
        if (!currentTenant) return;

        var response = await jsonRequest.post(`/api/request/api/tenant/detail`, { method: "get", application_key: "zy:services::customer-service", tenant_meta: currentTenant, data: {} })

        form.setFieldsValue(response.body.data.profile.ai_response);
    }

    const handleSubmit = async (values: any) => {
        console.log(values, 'values');

        var resp = await jsonRequest.post(`/api/request/api/tenant/profile`, {
            method: "put", application_key: "zy:services::customer-service", tenant_meta: currentTenant,
            data: {
                ai_response: values
            }
        })
        if (resp.ok) {
            form.setFieldsValue(resp.body.data.profile.ai_response);
            message.success("保存成功")
        } else {
            message.error("保存失败")
        }

    }

    return <div className="page coin-code-table">
        <h1>AI设置</h1>

        <div className="content" style={{ width: 600 }}>
            <Form form={form} name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={handleSubmit}
                autoComplete="off">
                <Form.Item label="类型" name="type">
                    <Input />
                </Form.Item>
                <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}>
                    {() => (
                        <>
                            <Form.Item label="Host" name={[form.getFieldValue("type"), "host"]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="ApiKey" name={[form.getFieldValue("type"), "api_key"]}>
                                <Input />
                            </Form.Item>
                        </>
                    )}
                </Form.Item>
                <Form.Item label="账号" name="member_id">
                    <Input />
                </Form.Item>
                <Form.Item label="是否开启AI" name="enableAI" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">保存</Button>
                </Form.Item>
            </Form>
        </div>

    </div>

}