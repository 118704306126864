import Shoppe from "./index";

const router = {
  path: "/shoppe",
  element: <Shoppe />,
  children: [
    {
      path: "/shoppe/user",
      async lazy() {
        return {
          Component: (await import("./user/shoppeUser")).default,
        };
      },
    },
    {
      path: "/shoppe/user/statistics",
      async lazy() {
        return {
          Component: (await import("./user/userStatistics")).default,
        };
      },
    },
    {
      path: "/shoppe/order",
      async lazy() {
        return {
          Component: (await import("./order/shoppeOrder")).default,
        };
      },
    },
    {
      path: "/shoppe/order/checkOrder",
      async lazy() {
        return {
          Component: (await import("./order/checkOrder")).default,
        };
      },
    },
    {
      path: "/shoppe/product/manage",
      async lazy() {
        return {
          Component: (await import("./product/manage")).default,
        };
      },
    },
    {
      path: "/shoppe/product/statistics",
      async lazy() {
        return {
          Component: (await import("./product/productStatistics")).default,
        };
      },
    },
    {
      path: "/shoppe/product/addProduct",
      async lazy() {
        return {
          Component: (await import("./product/addProduct")).default,
        };
      },
    },
    {
      path: "/shoppe/product/checkProduct",
      async lazy() {
        return {
          Component: (await import("./product/checkProduct")).default,
        };
      },
    },
    {
      path: "/shoppe/product/productComment",
      async lazy() {
        return {
          Component: (await import("./product/productComment")).default,
        };
      },
    },
    {
      path: "/shoppe/product/comment",
      async lazy() {
        return {
          Component: (await import("./product/productComment")).default,
        };
      },
    },
    {
      path: "/shoppe/product/classify",
      async lazy() {
        return {
          Component: (await import("./product/classify")).default,
        };
      },
    },
    {
      path: "/shoppe/product/specification",
      async lazy() {
        return {
          Component: (await import("./product/specification")).default,
        };
      },
    },
    {
      path: "/shoppe/finance/transaction", // 财务管理
      async lazy() {
        return {
          Component: (await import("./finance/transaction")).default,
        };
      },
    },
    {
      path: "/shoppe/finance/coin", // 财务管理
      async lazy() {
        return {
          Component: (await import("./finance/coin")).default,
        };
      },
    },
    {
      path: "/shoppe/setting/channel",
      async lazy() {
        return {
          Component: (await import("./setting/Channel")).default,
        };
      },
    },
    {
      path: "/shoppe/setting/company",
      async lazy() {
        return {
          Component: (await import("./setting/Company")).default,
        };
      },
    },
    {
      path: "/shoppe/setting/configuration",
      async lazy() {
        return {
          Component: (await import("./setting/Configuration")).default,
        };
      },
    },
    {
      path: "/shoppe/setting/feature",
      async lazy() {
        return {
          Component: (await import("./setting/Feature")).default,
        };
      },
    },
    {
      path: "/shoppe/decorate/carousel",
      async lazy() {
        return {
          Component: (await import("./decorate/Carousel")).default,
        };
      },
    }
  ],
};
export default router;
