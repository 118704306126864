import { Button, message, Space } from "antd";
import { showMdModal } from "./MdForm";

export default function CatalogueInfo({ value, onChange, fullMdUrl }: any) {

    const editCatalogue = () => {
        if (!fullMdUrl) {
            message.warning("请先生成完整markdown");
            return;
        }
        showMdModal({
            content: value,
            showAi: true,
            isCatalogue: true,
            fullMdUrl: fullMdUrl,
            onOk: afterEdit,
        });
    };

    const afterEdit = ({ content }: any) => {
        onChange(content);
    };

    return (
        <Space>
            <Button type="link" onClick={editCatalogue}>编辑目录</Button>
        </Space>
    );
}
