import { Navigate, Outlet } from "react-router-dom";
import { useGlobalState } from "./components/global";
import { Layout, Menu, Button, Modal, Form, message, Input, Select, Tag } from 'antd'
import { Link } from "react-router-dom"
import {
    AppstoreAddOutlined,
    CustomerServiceOutlined, IdcardTwoTone,
    KeyOutlined,
    LikeOutlined,
    MessageOutlined,
    PayCircleOutlined,
    ReadOutlined,
    SettingTwoTone,
    ShopOutlined, ShoppingCartOutlined,
    ToolOutlined,
} from "@ant-design/icons";

import { useEffect, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import useLocalStorageState from "use-local-storage-state";

const { Header } = Layout;

function Root(props: any) {
    const [editPasswordForm] = Form.useForm();

    const { user, navActiveKey, jsonRequest: jsonClient, currentTenant, signOut, setTenant } = useGlobalState()
    const [persistentCurrentTenant, setCurrentTenant] = useLocalStorageState('current-tenant', undefined)

    const [grantedTenants, setGrantedTenants] = useState([])

    // const [permission, setLocalStoragePermission] = useLocalStorageState<string[]>('sign-in-permission', { defaultValue: [] })

    dayjs.extend(utc)
    dayjs.extend(timezone)
    dayjs.tz.setDefault('UTC')

    // 遍历获取所有数据
    function getAllData(data: any) {
        return data.flatMap((item: any) => {
            const childrenCodes = item.children?.length
                ? getAllData(item.children)
                : [];
            return [item.code, ...childrenCodes];
        });
    }

    useEffect(() => {
        // 获取授权的租户
        jsonClient.get("/api/ldapUser/granted_tenant", {})
            .then(resp => resp.json())
            .then(({ data }: any) => {
                setGrantedTenants(data || [])
                handleChangeTenant(persistentCurrentTenant || (data?.[0] ? data?.[0]?.meta : null), false)
            })
            .catch(any => console.log(any))
        return () => { }
        // // 获取全部租户列表，然后用户库返回的用户角色拥有租户权限比对，有的才展示
        // jsonClient.get("/api/tenant", {})
        //     .then(resp => resp.json())
        //     .then(({ data }: any) => {
        //         jsonClient.post("/api/request", {
        //             path: "/api/v1/auth/user/role/tenant",
        //             method: "get",
        //             application_key: "zy:services::user-library",
        //             tenant_meta: 'zy:application::acupoint-test',
        //             data: {
        //                 accountId: user.accountId,
        //             },
        //         })
        //             .then(resp => resp.json())
        //             .then(({ data: roleTenants }: any) => {
        //                 let tenants: any = [];
        //                 for (let i = 0; i < data.length; i++) {
        //                     for (let j = 0; j < roleTenants.length; j++) {
        //                         if (data[i]?.meta === roleTenants[j].tenantMeta) {
        //                             tenants.push(data[i]);
        //                             break;
        //                         }
        //                     }
        //                 }
        //                 setGrantedTenants(tenants);
        //                 handleChangeTenant(persistentCurrentTenant || tenants?.[0]?.meta, false)
        //                 // jsonClient.post("/api/request", {
        //                 //     path: "/api/v1/auth/user/menu",
        //                 //     method: "get",
        //                 //     application_key: "zy:services::user-library",
        //                 //     tenant_meta: 'zy:application::acupoint-test',
        //                 //     data: {
        //                 //         accountId: user.accountId,
        //                 //         userTenantMeta: persistentCurrentTenant || tenants?.[0]?.meta
        //                 //     },
        //                 // })
        //                 //     .then(resp => resp.json())
        //                 //     .then(({ data }: any) => {
        //                 //         const value = getAllData(data);
        //                 //         setLocalStoragePermission(value);
        //                 //     });
        //             }).catch(any => console.log(any))
        //     }).catch(any => console.log(any))
        return () => {

        }
    }, [jsonClient])

    const sendRequest = () => {
        jsonClient.get("/api/session/getToken", {})
            .then(resp => resp.json())
            .then(({ data: user, token }) => {
                if (token !== null) {
                    jsonClient.setUser({ ...user, token: token });
                } else {
                    logOut();
                }
            });
    };

    useEffect(() => {
        sendRequest();
        const initialTimeout = setTimeout(() => {
            sendRequest();
        }, 60000);
        return () => clearTimeout(initialTimeout);
    }, []);

    if (!user)
        return <Navigate to="/sign_in" />

    const logOut = () => {
        signOut()
        return <Navigate to="/sign_in" />
    }

    const changePasswordConfirm = () => {
        const handleEditPasswordSubmit = (values: any, modalClose: () => void) => {
            jsonClient.put("/api/ldapUser/password", values)
                .then((response: { json: () => any; }) => response.json())
                .then(({ data, message: msg }: any) => {
                    if (!!data && !!data.success) {
                        message.success({
                            content: <span>密码修改成功，建议<a href="#/" onClick={() => {
                                logOut();
                                message.destroy()
                            }}>重新登录</a></span>, duration: 5
                        })
                        modalClose()
                    } else
                        message.warning(msg)
                })
        }

        Modal.confirm({
            title: '修改密码',
            content: <Form form={editPasswordForm} name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                autoComplete="off">
                <Form.Item label="原密码" name="origin_password" rules={[{ required: true, message: '原始密码能为空' }]}>
                    <Input.Password />
                </Form.Item>

                <Form.Item label="新密码" name="new_password" rules={[{ required: true, message: '新密码不能为空' }]}>
                    <Input.Password />
                </Form.Item>
            </Form>,
            onOk: (close: any) => {
                editPasswordForm
                    .validateFields()
                    .then((values: any) => {
                        editPasswordForm.resetFields();
                        handleEditPasswordSubmit(values, close);
                    })
                    .catch((info: any) => {
                        console.log('Validate Failed:', info);
                    });
                return false
            }
        });
    }

    const handleChangeTenant = (v: any, persistent: boolean = true) => {
        setTenant(v)
        if (persistent || !persistentCurrentTenant)
            setCurrentTenant(v)
    }

    return <div className="App">
        <main role="main" className="container">
            <div style={{ padding: "5px" }}>
                <Layout className="layout">
                    <Header style={{ background: "#fff", padding: 0, display: "flex" }}>
                        <div className="logo">
                        </div>

                        <Menu theme="light" mode="horizontal" selectedKeys={[navActiveKey!]}
                            style={{ border: "none", flexGrow: 1 }}>
                            <Menu.Item key="MALL" icon={<ShoppingCartOutlined style={{ color: "#1677ff" }} />}>
                                <Link to="/mall/order">积分商城</Link>
                            </Menu.Item>
                            <Menu.Item key="CONTENT" icon={<ReadOutlined style={{ color: "#1677ff" }} />}>
                                <Link to="/knowledge/content/">内容库</Link>
                            </Menu.Item>
                            <Menu.Item key="SHOPPE" icon={<ShopOutlined style={{ color: "#1677ff" }} />}>
                                <Link to="/shoppe/user/statistics">商城</Link>
                            </Menu.Item>
                            <Menu.Item key="FINANCE" icon={<PayCircleOutlined style={{ color: "#1677ff" }} />}>
                                <Link to="/finance/coin/code-table">财务</Link>
                            </Menu.Item>
                            <Menu.Item key="USER-LIBRARY" icon={<IdcardTwoTone style={{ color: "#1677ff" }} />}>
                                <Link to="/user-library/user-library">用户库</Link>
                            </Menu.Item>
                            <Menu.Item key="KEY" icon={<KeyOutlined style={{ color: "#1677ff" }} />}>
                                <Link to="/key/keys">密钥服务</Link>
                            </Menu.Item>
                            <Menu.Item key="CUSTOMER-SERVICE" icon={<CustomerServiceOutlined style={{ color: "#1677ff" }} />}>
                                <Link to="/customer-service/chat">客服</Link>
                            </Menu.Item>
                            <Menu.Item key="RELEASE" icon={<AppstoreAddOutlined style={{ color: "#1677ff" }} />}>
                                <Link to="/release/identity">发行</Link>
                            </Menu.Item>
                            <Menu.Item key="OPERATOR" icon={<SettingTwoTone />}>
                                <Link to="/operator/tenant/index">运维</Link>
                            </Menu.Item>
                            <Menu.Item key="TOOL" icon={<ToolOutlined style={{ color: "#1677ff" }} />}>
                                <Link to="/tool/jose/signature">工具</Link>
                            </Menu.Item>
                            <Menu.Item key="NOTIFICATION" icon={<MessageOutlined style={{ color: "#1677ff" }} />}>
                                <Link to="/notification/channel/index">消息通知</Link>
                            </Menu.Item>
                            <Menu.Item key="COOPERATION" icon={<LikeOutlined style={{ color: "#1677ff" }} />}>
                                <Link to="/cooperation/collaborators">商务合作</Link>
                            </Menu.Item>
                        </Menu>
                        <div className="side">
                            <Select value={currentTenant} style={{ minWidth: 150 }}
                                onChange={(v) => handleChangeTenant(v)}>
                                {grantedTenants.map(((tenant: any) => (
                                    <Select.Option value={tenant.meta} key={tenant.meta}><Tag>租户</Tag> {tenant.name}
                                    </Select.Option>
                                )))}
                            </Select>
                            <span style={{ padding: "4px 15px" }}> {user.name}</span>
                            <Button type="link" onClick={() => changePasswordConfirm()}>修改密码</Button>
                            <Button type="link" onClick={() => logOut()}>退出</Button>
                        </div>
                    </Header>
                </Layout>
            </div>

            <Outlet />
        </main>
    </div>
}

export default Root