import { Badge, Button, Form, Input, message, Popconfirm, Space, Tree } from "antd";
import KnowledgeUploader from "../../../../components/KnowledgeUploader";
import { DownOutlined, UploadOutlined } from "@ant-design/icons";
import { useGlobalState } from "../../../../../components/global";
import PdfFileInfo from "./PdfFileInfo";
import { showMdModal } from "./MdForm";
import { useState } from "react";
import CatalogueInfo from "./CatalogueInfo";

export default function EBookInput({ prefix, fullPrefix, form, field }: any) {
    const { currentTenant, jsonRequest } = useGlobalState();
    const [treeData, setTreeData] = useState(form.getFieldValue([...fullPrefix, "sectionList"]) || []);
    const [epubNeedUpdate, setEpubNeedUpdate]: any = useState();
    const [markdownNeedUpdate, setMarkdownNeedUpdate]: any = useState();

    const removeFileItem = (index: any) => {
        const fieldName = [...fullPrefix, "pdfList"];
        const list = form.getFieldValue(fieldName);
        list.splice(index, 1);
        form.setFieldValue(fieldName, list);
        form.validateFields(fieldName, { validateOnly: true });
    };

    const notAllowMdUrls = (mdUrls: any) => {
        if (!mdUrls) return true;
        for (let mdUrlsKey in mdUrls) {
            if (!mdUrls[mdUrlsKey]) return true;
        }
        return false;
    };

    const mergeMarkdowns = async () => {
        try {
            const pdfList = form.getFieldValue([...fullPrefix, "pdfList"]);
            const mdUrls = pdfList?.map((e: any) => e.md?.mdUrl);
            if (notAllowMdUrls(mdUrls)) {
                message.warning("pdf列表为空或存在未生成完成的PDF");
                return;
            }
            message.info("正在合并，请稍后");
            const { data } = await jsonRequest.post("/api/request", {
                path: `/v1/ebook/md/merge`,
                method: "post",
                application_key: "zy:application::knowledge-foundation",
                tenant_meta: currentTenant,
                data: { mdUrls },
            })
                .then((resp: any) => resp.json());
            form.setFieldValue([...fullPrefix, "fullMdUrl"], data.mdUrl);
            form.validateFields(fullPrefix, { validateOnly: true });
            message.success("合并成功");
        } catch (e) {
            console.error(e);
            message.error("合并失败");
        }
    };

    const handleCatalogue = async () => {
        try {
            const catalogue = form.getFieldValue([...fullPrefix, "catalogue"]);
            const mdUrl = form.getFieldValue([...fullPrefix, "fullMdUrl"]);
            if (!catalogue) {
                message.warning("请先填写目录");
                return;
            }
            message.info("请稍后");
            const { data } = await jsonRequest.post("/api/request", {
                path: `/v1/ebook/md/catalogue`,
                method: "post",
                application_key: "zy:application::knowledge-foundation",
                tenant_meta: currentTenant,
                data: { catalogue, mdUrl },
            })
                .then((resp: any) => resp.json());
            message.success("操作完成");
        } catch (e) {
            console.error(e);
            message.error("操作失败");
        }
    };

    const getSection = async () => {
        try {
            const fullMdUrl = form.getFieldValue([...fullPrefix, "fullMdUrl"]);
            if (!fullMdUrl) {
                message.warning("markdowns为空，请先生成完整markdown");
                return;
            }
            message.info("正在生成，请稍后");
            const { data } = await jsonRequest.post("/api/request", {
                path: `/v1/ebook/md/section`,
                method: "get",
                application_key: "zy:application::knowledge-foundation",
                tenant_meta: currentTenant,
                data: { url: fullMdUrl },
            })
                .then((resp: any) => resp.json());

            updateSectionList(data);
            message.success("生成成功");
        } catch (e) {
            console.error(e);
            message.error("生成失败");
        }
    };

    // 递归删除拖拽节点
    const removeNode: any = (data: any[], key: string) => {
        for (let i = 0; i < data.length; i++) {
            if (data[i].uuid === key) {
                return data.splice(i, 1)[0];
            }
            if (data[i].children) {
                const result = removeNode(data[i].children, key);
                if (result) return result;
            }
        }
    };

    const removeSectionNode = (node: any) => {
        const sectionList = form.getFieldValue([...fullPrefix, "sectionList"]);
        // 深拷贝数据，避免直接修改原数据
        const newData = JSON.parse(JSON.stringify(sectionList));
        const result = removeNode(newData, node.uuid);
        if (result) {
            updateSectionList(newData);
        }
    };

    const onDrop = (info: any) => {
        const { dragNode, node, dropPosition, dropToGap } = info;
        const sectionList = form.getFieldValue([...fullPrefix, "sectionList"]);

        // 深拷贝数据，避免直接修改原数据
        const newData = JSON.parse(JSON.stringify(sectionList));

        // 递归查找目标节点并插入
        const insertNode = (data: any[], key: string, node: any, position: number) => {
            for (let i = 0; i < data.length; i++) {
                if (data[i].uuid === key) {
                    if (dropToGap) {
                        // 插入到节点前后
                        const pos = position > i ? position : i;
                        data.splice(pos, 0, node);
                    } else {
                        // 插入到节点内部
                        if (!data[i].children) data[i].children = [];
                        data[i].children.push(node);
                    }
                    return true;
                }
                if (data[i].children) {
                    const found = insertNode(data[i].children, key, node, position);
                    if (found) return true;
                }
            }
        };

        // 获取拖拽的节点
        const draggedNode = removeNode(newData, dragNode.key);
        if (draggedNode) {
            // 更新拖拽节点的 level
            const updateLevel = (node: any, parentLevel: number, parentNode: any) => {
                node.level = parentLevel + 1;
                // 如果是插入到节点前后（dropToGap 为 true），则 pid 与目标节点的 pid 相同
                node.pid = dropToGap ? (parentNode.pid || "0") : (parentNode ? parentNode.uuid : "0");
                if (node.children) {
                    node.children.forEach((child: any) => updateLevel(child, node.level, node));
                }
            };

            // 计算目标位置的 level
            let targetLevel = dropToGap ? node.level : node.level + 1;
            updateLevel(draggedNode, targetLevel - 1, node);

            // 插入节点
            insertNode(newData, node.key, draggedNode, dropPosition);
        }
        updateSectionList(newData);
    };

    const updateSectionList = (newData: any) => {
        form.setFieldValue([...fullPrefix, "sectionList"], newData);
        setTreeData(newData);
        setEpubNeedUpdate(true);
        setMarkdownNeedUpdate(true);
    };

    const addTreeNode = (parent: any) => {
        showMdModal({
            parent: parent,
            content: "",
            level: parent?.level + 1,
            showAi: true,
            onOk: async ({ title, content }: any) => {
                const sectionList = form.getFieldValue([...fullPrefix, "sectionList"]);
                // 深拷贝避免直接修改引用
                const newData = JSON.parse(JSON.stringify(sectionList));
                const newNode = {
                    uuid: crypto.randomUUID(),
                    pid: parent?.uuid || 0,
                    title: title,
                    content: content,
                    level: (parent?.level ?? 0) + 1,
                    children: [],
                };
                const { data: newNodes } = await mdSection({ ...newNode, title, content });

                // 递归查找并插入节点
                const insertNode = (data: any[], parentUuid: string) => {
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].uuid === parentUuid) {
                            if (!data[i].children) data[i].children = [];
                            data[i].children.push(...(newNodes || []));
                            return true;
                        }
                        if (data[i].children) {
                            const found = insertNode(data[i].children, parentUuid);
                            if (found) return true;
                        }
                    }
                    return false;
                };

                if (!parent?.uuid) newData.push(...(newNodes || []));
                else insertNode(newData, parent.uuid);

                updateSectionList(newData);
            },
        });
    };

    const editTreeNode = (node: any) => {
        const { title, content } = node;
        showMdModal({
            title: title || " ",
            content: content,
            level: node.level,
            showAi: true,
            onOk: async ({ title, content }: any) => {
                const sectionList = form.getFieldValue([...fullPrefix, "sectionList"]);
                // 深拷贝避免直接修改引用
                const newData = JSON.parse(JSON.stringify(sectionList));
                const { data: newNodes } = await mdSection({ ...node, title, content });

                // 递归查找并更新节点
                const updateNode = (data: any[], uuid: string) => {
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].uuid === uuid) {
                            data.splice(i, 1, ...newNodes);
                            return true;
                        }
                        if (data[i].children) {
                            const found = updateNode(data[i].children, uuid);
                            if (found) return true;
                        }
                    }
                    return false;
                };

                updateNode(newData, node.uuid);
                updateSectionList(newData);
            },
        });
    };

    const mdSection = async (node: any) => {
        return jsonRequest.post("/api/request", {
            path: `/v1/ebook/md/section`,
            method: "post",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: node,
        })
            .then((resp: any) => resp.json());
    };

    const treeNodeRenderer = (node: any) => {
        return <Space key={node.uuid}>
            <span>{node.title}</span>
            <Button size="small" type="link" onClick={() => editTreeNode(node)}>修改</Button>
            <Button size="small" type="link" onClick={() => addTreeNode(node)}>新增</Button>
            <Popconfirm title="确认删除吗？" onConfirm={() => removeSectionNode(node)}>
                <Button size="small" type="link" danger>删除</Button>
            </Popconfirm>
        </Space>;
    };

    const updateEpub = async () => {
        if (!treeData || treeData.length === 0) {
            message.warning("请先生成结构");
            return;
        }
        try {
            message.info("正在生成，请稍后");
            const { data } = await jsonRequest.post("/api/request", {
                path: `/v1/ebook/epub`,
                method: "post",
                application_key: "zy:application::knowledge-foundation",
                tenant_meta: currentTenant,
                data: {
                    contentId: form.getFieldValue("ownerId"),
                    sections: form.getFieldValue([...fullPrefix, "sectionList"]),
                },
            })
                .then((resp: any) => resp.json());

            form.setFieldValue([...fullPrefix, "epubUrl"], data.epubUrl);
            message.success("epub生成成功");
        } catch (e) {
            console.error(e);
            message.error("epub生成失败");
        }
    };

    const updateMarkdown = async () => {
        if (!treeData || treeData.length === 0) {
            message.warning("请先生成结构");
            return;
        }
        const mdUrl = form.getFieldValue([...fullPrefix, "fullMdUrl"]);
        if (!mdUrl) {
            message.warning("请先生成完整markdown");
            return;
        }
        try {
            message.info("正在更新，请稍后");
            const { data } = await jsonRequest.post("/api/request", {
                path: `/v1/ebook/md/builder`,
                method: "post",
                application_key: "zy:application::knowledge-foundation",
                tenant_meta: currentTenant,
                data: {
                    mdUrl,
                    sections: form.getFieldValue([...fullPrefix, "sectionList"]),
                },
            })
                .then((resp: any) => resp.json());
            message.success("更新成功");
        } catch (e) {
            console.error(e);
            message.error("更新失败");
        }
    };

    return <>
        <Form.Item label="原始书籍" rules={[{ required: true, message: "不能为空" }]}
                   name={[...prefix, "pdfList"]} labelCol={{ span: 4 }} wrapperCol={{ span: 19 }}>
            <KnowledgeUploader path={[currentTenant, "product"].join("/")} type="ebook"
                               accept=".pdf" multiUpload showUploadList={false} maxSize={150}>
                {<Button icon={<UploadOutlined />} disabled={treeData?.length}>上传PDF</Button>}
            </KnowledgeUploader>
        </Form.Item>
        <Form.Item dependencies={[[...fullPrefix, "pdfList"]]} label=" " labelCol={{ span: 4 }}
                   wrapperCol={{ span: 19 }}>
            {({ getFieldValue }: any) => <Space direction="vertical" style={{ width: "100%" }}>
                {getFieldValue([...fullPrefix, "pdfList"])?.map((e: any, index: any) =>
                    <PdfFileInfo key={e.uid} file={e} onRemove={() => removeFileItem(index)}
                                 disabled={treeData?.length} />)}
            </Space>}
        </Form.Item>
        <Form.Item label="书籍目录" rules={[{ required: true, message: "不能为空" }]} initialValue=""
                   dependencies={[[...fullPrefix, "fullMdUrl"]]}
                   name={[...prefix, "catalogue"]} labelCol={{ span: 4 }} wrapperCol={{ span: 19 }}>
            <CatalogueInfo fullMdUrl={form.getFieldValue([...fullPrefix, "fullMdUrl"])} />
        </Form.Item>
        <Form.Item
            dependencies={[[...fullPrefix, "pdfList"], [...fullPrefix, "sectionList"], [...fullPrefix, "fullMdUrl"], [...fullPrefix, "catalogue"]]}
            label="markdown操作"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 19 }}>
            {({ getFieldValue }: any) => {
                return <Space style={{ width: "100%" }}>
                    <Button size="small" type="link" onClick={mergeMarkdowns}
                            disabled={!getFieldValue([...fullPrefix, "pdfList"])?.length || getFieldValue([...fullPrefix, "sectionList"])?.length}
                    >生成完整markdown</Button>
                    <Button size="small" type="link" onClick={handleCatalogue}
                            disabled={!getFieldValue([...fullPrefix, "catalogue"])?.length || !getFieldValue([...fullPrefix, "fullMdUrl"])?.length}
                    >目录修正</Button>
                    <Button size="small" type="link" onClick={() => showMdModal({
                        mdUrl: getFieldValue([...fullPrefix, "fullMdUrl"]),
                        hideMd: true,
                    })}>预览</Button>
                </Space>;
            }}
        </Form.Item>
        <Form.Item name={[...prefix, "fullMdUrl"]} label="完整markdown地址"
                   labelCol={{ span: 4 }} wrapperCol={{ span: 19 }}>
            <Input disabled />
        </Form.Item>
        <Form.Item name={[...prefix, "epubUrl"]} label="epub链接"
                   labelCol={{ span: 4 }} wrapperCol={{ span: 19 }}>
            <Input disabled />
        </Form.Item>
        <Form.Item name={[...prefix, "sectionList"]} label="markdown结构" hidden
                   labelCol={{ span: 4 }} wrapperCol={{ span: 19 }}>
            <Input />
        </Form.Item>
        <Form.Item label="markdown结构" labelCol={{ span: 4 }} wrapperCol={{ span: 19 }}>
            <Space>
                <Button size="small" type="link" onClick={getSection}>生成结构</Button>
                <Button size="small" type="link" onClick={() => addTreeNode({})}>新增章节</Button>
                <Badge dot={epubNeedUpdate}>
                    <Button size="small" type="link" onClick={updateEpub}
                            disabled={!form.getFieldValue([...fullPrefix, "sectionList"])?.length}>生成epub</Button>
                </Badge>
                <Badge dot={markdownNeedUpdate}>
                    <Button size="small" type="link" onClick={updateMarkdown}>更新markdown</Button>
                </Badge>
            </Space>
        </Form.Item>
        <Form.Item label=" " labelCol={{ span: 4 }} wrapperCol={{ span: 19 }}>
            <Tree draggable blockNode onDrop={onDrop} height={500}
                  treeData={treeData} showLine switcherIcon={<DownOutlined />}
                  titleRender={treeNodeRenderer}
                  fieldNames={{ title: "title", key: "uuid", children: "children" }} />
        </Form.Item>
    </>;
}