import { Button, Form, Input, InputNumber, Radio, Select, TreeSelect } from "antd";
import { useEffect, useState } from "react";
import { useGlobalState } from "../../components/global";

export default function RoleForm({ form, item, tenantMeta }: any) {
    const [menu, setMenu] = useState<any>();
    const { jsonRequest, currentTenant } = useGlobalState();
    const [isCheckAll, setIsCheckAll] = useState(true);
    const [checkedKeys, setCheckedKeys] = useState([]);
    const [cascade, setCascade] = useState(true);
    const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const handleDropdownVisibleChange = (visible: boolean) => {
        setDropdownVisible(visible);
    };

    const toggleExpand = () => {
        if (expandedKeys.length > 0) {
            setExpandedKeys([]);
        } else {
            const keys = getAllData(menu);
            setExpandedKeys(keys);
        }
        setDropdownVisible(true);
    };

    const handleCheck = (checkedValues: any) => {
        if (!cascade) {
            checkedValues = checkedValues.flatMap((item: any) => item.value);
        }
        setCheckedKeys(checkedValues);
        form.setFieldValue('menuIds', checkedValues);
    };

    // 全选全不选
    const checkAll = () => {
        if (isCheckAll) {
            const allKeys = getAllData(menu);
            setCheckedKeys(allKeys);
            form.setFieldValue('menuIds', allKeys);
        } else {
            setCheckedKeys([]);
            form.setFieldValue('menuIds', []);
        }
        setIsCheckAll(!isCheckAll);
        setDropdownVisible(true);
    };

    const toggleCascade = () => {
        setCascade(!cascade);
        setDropdownVisible(true);
    };

    // 遍历将数据转换为树形结构
    function transfromData(data: any) {
        return data.map((item: any) => {
            const { name, id, children } = item;
            const res = { label: name, value: id || name, key: id || name, children: [] };
            if (children && children.length) {
                res.children = transfromData(children);
            }
            return res;
        });
    }
    
    // 遍历获取所有数据
    function getAllData(data: any) {
        return data.flatMap((item: any) => {
           const key = item.children ? getAllData(item.children) : [];
           return [...key, item.value];
        });
    }

    const getMenuData = async () => {
        // 获取菜单下拉数据
        jsonRequest.post("/api/request",
            {
                path: `/api/v1/menu/tree`,
                method: "get",
                application_key: "zy:services::user-library",
                tenant_meta: currentTenant,
                data: {},
            })
            .then((resp: any) => resp.json())
            .then(async ({ data }: any) => {
                const result = transfromData(data);
                setMenu(result);
            });
    };

    useEffect(() => {
        getMenuData();
        form.setFieldsValue(item);
        setCheckedKeys(item.menuIds);
        form.setFieldValue('ownerTenantMeta', item.tenantMeta);
    }, []);

    return (
        <Form form={form} size="small" name="role" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} autoComplete="off">
            <Form.Item label="租户" name="ownerTenantMeta" rules={[{ required: true, message: '不能为空' }]}>
                <Select placeholder="请选择租户"
                    options={tenantMeta?.map((e: any) => ({ value: e.tenantMeta, label: e.name }))}
                />
            </Form.Item>
            <Form.Item label="角色名称" name="name" rules={[{ required: true, message: '不能为空' }]}>
                <Input placeholder="请输入角色名称" />
            </Form.Item>
            <Form.Item label="权限字符" name="code" rules={[{ required: true, message: '不能为空' }]}>
                <Input placeholder="请输入权限字符串" />
            </Form.Item>
            <Form.Item label="排序号" name="index" initialValue={0} rules={[{ required: true, message: '不能为空' }]}>
                <InputNumber min={0} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item label="状态" name="state" rules={[{ required: true, message: '不能为空' }]} initialValue={true}>
                <Radio.Group>
                    <Radio value={true}>启用</Radio>
                    <Radio value={false}>禁用</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item label="菜单权限" name="menuIds" rules={[{ required: true, message: '不能为空' }]}>
                <div style={{ display: 'flex', justifyContent: 'space-around', paddingBottom: '3%' }}>
                    <Button onClick={toggleExpand}>
                    {expandedKeys.length > 0 ? '折叠' : '展开'}
                    </Button>
                    <Button onClick={checkAll}>
                        {isCheckAll ? '全选' : '全不选'}
                    </Button>
                    <Button onClick={toggleCascade}>
                        {cascade ? '取消联动' : '父子联动'}
                    </Button>
                </div>
                <TreeSelect
                    treeData={menu}
                    multiple
                    allowClear
                    treeCheckable
                    placeholder="请选择菜单权限"
                    showCheckedStrategy="SHOW_ALL"
                    treeExpandedKeys={expandedKeys}
                    value={checkedKeys}
                    onChange={handleCheck}
                    treeCheckStrictly={!cascade}
                    open={dropdownVisible}
                    onTreeExpand={(keys: any) => setExpandedKeys(keys)}
                    onDropdownVisibleChange={handleDropdownVisibleChange}
                />
            </Form.Item>
        </Form>
    )
}