import { useState, useEffect } from "react";
import { message, Upload } from "antd";
import { useGlobalState } from "../../components/global";
import Sha256Encode from "../../components/sha256";
import OssPath from "../../components/oss_path";

export default function KnowledgeUploader({ type, value, onChange, children, multiUpload, maxSize, ...props }: any) {
    const [OSSData, setOSSData]: any = useState();

    const { jsonRequest, currentTenant } = useGlobalState();

    useEffect(() => {
        init();
    }, []);

    const init = () => {
        jsonRequest.post("/api/request", {
            path: `/v1/aliyun/oss`,
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: { type },
        })
            .then((resp: any) => resp.json())
            .then(({ data }: any) => setOSSData(data));
    };

    const handleChange = async ({ fileList }: any) => {
        if (!multiUpload) {
            // 上传单个文件，只处理第一个file对象
            const [file] = fileList;
            if (!file) {
                onChange?.([]);
                return;
            }
            await setFileUrl(file);
            onChange?.([file]);
            return;
        }
        for (let i = 0; i < fileList.length; i++) {
            const item: any = fileList[i];
            if (item.status === "done" && !item.originUrl)
                await setFileUrl(item);
        }
        onChange?.(fileList);
    };

    const setFileUrl = async (file: any) => {
        const ossPath = await OssPath(jsonRequest, currentTenant);
        file.originUrl = file.url;
        file.url = `${ossPath}${file.url}`;
    };

    const onRemove = (file: any) => {

        // if (onChange) {
        //     onChange(files);
        // }
    };

    const getExtraData = (file: any) => ({
        key: file.url,
        OSSAccessKeyId: OSSData?.keyId,
        policy: OSSData?.policy,
        Signature: OSSData?.signature,
    });

    const beforeUpload = async (file: any) => {
        if (maxSize) {
            // 校验文件大小，如果文件大小超过maxSize，则return
            if (file.size > maxSize * 1024 * 1024) {
                message.error(`文件大小不能超过${maxSize}MB，请重新上传`);
                return Upload.LIST_IGNORE;
            }
        }
        if (!OSSData) return false;
        const expire = Number(OSSData.expire) * 1000;

        if (expire < Date.now()) {
            await init();
        }

        const file_content = await file.arrayBuffer();
        const file_name = await Sha256Encode(file_content);
        const extension = getFileExtension(file.name);
        file.url = [OSSData?.dir, `${file_name}${extension}`].join("/");
        return file;
    };

    const getFileExtension = (fileName: string) => {
        const parts = fileName.split(".");
        return parts.length > 1 ? `.${parts.pop()}` : "";
    };

    const uploadProps = {
        // name: 'file',
        fileList: value,
        action: OSSData?.host,
        onChange: handleChange,
        onRemove,
        data: getExtraData,
        beforeUpload,
    };

    return (
        <Upload {...props} {...uploadProps}>
            {children}
        </Upload>
    );
};
