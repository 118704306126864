import { ActionType, ProTable } from "@ant-design/pro-components";
import { Button, Form, message, Modal, Popconfirm, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useGlobalState } from "../../components/global";
import { useEffect, useRef } from "react";
import GlobalWrap from "../../components/globalWrap";
import CollaboratorsForm from "./CollaboratorsForm";
import moment from "moment";

export default function Collaborators(params: any) {
    const { jsonRequest, currentTenant } = useGlobalState();
    const tableRef = useRef<ActionType>();
    const [form] = Form.useForm();

    useEffect(() => {
        tableRef.current?.reload();
    }, [currentTenant]);

    const columns: any[] = [
        {
            title: "序号",
            valueType: "index",
            search: false,
            align: "center",
        },
        {
            title: "ID",
            dataIndex: "id",
            align: "center",
            search: false,
            ellipsis: true,
        },
        {
            title: "机构/医生名称",
            dataIndex: "name",
            align: "center",
            search: false,
            ellipsis: true,
        },
        {
            title: "联系人",
            dataIndex: "contact",
            align: "center",
            search: false,
            ellipsis: true,
        },
        {
            title: "联系方式",
            dataIndex: "phone",
            align: "center",
            search: false,
        },
        {
            title: "在售课程数量",
            dataIndex: "classNumber",
            align: "center",
            search: false,
        },
        {
            title: "状态",
            dataIndex: "status",
            align: "center",
            search: true,
            renderFormItem: () => (
                <Select
                    allowClear
                    placeholder='请选择联系状态'
                    options={[
                        { label: "待联系", value: 1 },
                        { label: "已联系", value: 2 },
                    ]}
                />
            ),
            render: (text: any) => {
                if (text === 1) {
                    return <span style={{ color: 'red' }}>待联系</span>
                } else if (text === 2) {
                    return <span style={{ color: 'green' }}>已联系</span>
                } return <span>未知</span>
            }
        },
        {
            title: "提交时间",
            dataIndex: "submittedAt",
            search: false,
            align: "center",
            render: (text: any) => {
                return moment(text).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        {
            title: "操作",
            key: 'operation',
            search: false,
            align: "center",
            render: (_: any, record: any) => {
                if (record.status === 1) {
                    return <Popconfirm
                        title="确定标记已联系？"
                        onConfirm={() => updateStatus({ ...record, status: 2 })}
                        okText="是"
                        cancelText="否"
                    >
                        <Button type="link">
                            标记已联系
                        </Button>
                    </Popconfirm>
                } else return null;
            },
        }
    ];

    const updateStatus = async (record: any) => {
        await jsonRequest.put("/api/collaborators/update", {
            ...record,
            tenantMeta: currentTenant
        })
            .then((resp: any) => resp.json())
            .then((data: any) => {
                if (data.code === 0) {
                    message.success("编辑成功");
                } else {
                    message.error(data.msg);
                }
                tableRef.current?.reload();
            }
            )
    }

    const handleAddOrEdit = (item: any) => {
        Modal.confirm({
            width: 500,
            title: !!item.userId ? `招募信息编辑 - ${item.name}` : "招募信息新增",
            content: <GlobalWrap><CollaboratorsForm form={form} item={item} /></GlobalWrap>,
            onOk: async (close) => {
                let values = await form.validateFields();
                await jsonRequest.post("/api/collaborators/create", {
                    ...values,
                    tenantMeta: currentTenant
                })
                    .then((resp: any) => resp.json())
                    .then((data: any) => {
                        if (data.code === 0) {
                            message.success("新增成功");
                        } else {
                            message.error(data.msg);
                        }
                    }
                    )
                tableRef.current?.reload();
                form.resetFields();
                close();
                return false;
            },
            onCancel: (close) => {
                form.resetFields();
                close();
            }
        })
    }

    const request = async (params: any, sort: any, filter: any) => {
        const { current, pageSize } = params;
        const result: any = await jsonRequest.get("/api/collaborators", {
            ...params,
            page: current,
            size: pageSize,
            tenantMeta: currentTenant
        }).then(resp => resp.json());
        const { data } = result;
        return { data: data?.entries, success: result.code === 0, total: data?.total_entries };
    };

    return <>
        <h1>合作招募</h1>
        <ProTable
            rowKey="id"
            scroll={{ x: 100 }}
            actionRef={tableRef}
            cardBordered
            columns={columns}
            request={request}
            pagination={{ defaultPageSize: 10, showSizeChanger: true }}
            search={{ span: 6, defaultCollapsed: false }}
            toolBarRender={() => [<Button
                key="button"
                icon={<PlusOutlined />}
                onClick={() => handleAddOrEdit({})}
                type="primary"
            >
                新增
            </Button>]}
        />
    </>;
}