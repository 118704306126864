import { Checkbox, Form, Select } from "antd";
import { useEffect, useState } from "react";
import { useGlobalState } from "../../components/global";

export default function GrantRoles({ form, user }: any) {
    const [data, setData]: any = useState({})
    const [loading, setLoading] = useState(true)
    const { jsonRequest } = useGlobalState()
    const [tenantForm] = Form.useForm()

    const [tenants, setTenants] = useState([])
    const [tenantRoles, setTenantRoles] = useState([])

    useEffect(() => {
        // 获取授权的租户
        jsonRequest.get("/api/tenant", {})
            .then(resp => resp.json())
            .then(({ data }: any) => {
                const defaultTenantMeta = data[0]?.meta
                setTenants(data || [])
                form.setFieldsValue({ tenant_meta: defaultTenantMeta })
                // 加载
                handleLoadRoles(defaultTenantMeta)
            })
            .catch(any => console.log(any))
        return () => {

        }

    }, [])

    const handleLoadRoles = (tenantMeta: string) => {
        // 获取授权的租户
        jsonRequest.get(`/api/tenant/${tenantMeta}/roles`, {})
            .then(resp => resp.json())
            .then(({ data }: any) => {
                setTenantRoles(data)
            })


        // 获取授权的租户
        jsonRequest.get(`/api/ldapUser/${user.id}/roles`, { tenant_meta: tenantMeta })
            .then(resp => resp.json())
            .then(({ data }: any) => {
                form.setFieldsValue({
                    role_ids: data?.map((item: any) => item.id)
                })
            })
    }



    return <div className="order-shipping-form">
        <Form form={form} size="small" name="basic" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} autoComplete="off">
            <Form.Item label="租户" name="tenant_meta" rules={[{ required: true, message: '不能为空' }]}>
                <Select onChange={(value) => handleLoadRoles(value)} >
                    {tenants.map((tenant: any) => (<Select.Option key={tenant.meta}>{tenant.name}</Select.Option>))}
                </Select>
            </Form.Item>

            <Form.Item label="角色" name="role_ids" >
                <Checkbox.Group options={tenantRoles.map(((role: any) => ({ label: role.name, value: role.id })))} />
            </Form.Item>
        </Form>
    </div>
}