import { Form, Input, InputNumber, Radio } from "antd";

export default function CollaboratorsForm({ form, item }: any) {
    return (
        <Form form={form} size="small" name="CollaboratorsForm" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} autoComplete="off">
            <Form.Item label="机构/医生名称" name="name" rules={[{ required: true, message: '不能为空' }]}>
                <Input placeholder="请输入机构/医生名称" />
            </Form.Item>
            <Form.Item label="联系人" name="contact" rules={[{ required: true, message: '不能为空' }]}>
                <Input placeholder="请输入联系人" />
            </Form.Item>
            <Form.Item label="联系方式" name="phone" rules={[{ required: true, message: '不能为空' }]}>
                <Input placeholder="请输入联系方式" />
            </Form.Item>
            <Form.Item label="在售课程数量" name="classNumber" rules={[{ required: true, message: '不能为空' }]}>
                <InputNumber placeholder="请输入在售课程数量" style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item label="状态" name="status" initialValue={1} rules={[{ required: true, message: '不能为空' }]}>
                <Radio.Group>
                    <Radio value={1}>待联系</Radio>
                    <Radio value={2}>已联系</Radio>
                </Radio.Group>
            </Form.Item>
        </Form>
    )
}