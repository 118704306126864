import { ActionType, ProTable } from "@ant-design/pro-components";
import { Button, Form, Input, message, Modal, Popconfirm, Select, Switch } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useGlobalState } from "../../components/global";
import { useEffect, useRef, useState } from "react";
import GlobalWrap from "../../components/globalWrap";
import MenuForm from "./menuForm";

enum menuTypes {
    'catalogue' = '目录',
    'menu' = '菜单',
    'button' = '按钮'
};

export default function Menu(params: any) {
    const { jsonRequest, currentTenant } = useGlobalState();
    const tableRef = useRef<ActionType>();
    const [application, setApplication]: any = useState([]);
    const [form] = Form.useForm();

    useEffect(() => {
        tableRef.current?.reload();
    }, [currentTenant]);

    useEffect(() => {
        getApplication();
    }, []);

    const getApplication = async () => {
        const { data } = await jsonRequest.post("/api/request", {
            path: "/api/v1/menu/application",
            method: "get",
            application_key: "zy:services::user-library",
            tenant_meta: currentTenant,
            data: {},
        }).then((resp: any) => resp.json());
        setApplication(data);
    };

    const columns: any[] = [
        {
            title: "排序号",
            dataIndex: "index",
            align: "center",
            search: false,
            width: 100,
        },
        {
            title: "菜单名称",
            dataIndex: "name",
            align: "center",
            search: false,
            width: 200,
            render: (_: any, item: any) => {
                return <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    <img src={item.icon} style={{
                        width: 20,
                        height: 20,
                        objectFit: "cover",
                        verticalAlign: 'middle'
                    }} />
                    <span style={{ lineHeight: 1 }}>{item.name}</span>
                </div>
            },
        },
        {
            title: "类型",
            dataIndex: "menuType",
            align: "center",
            search: false,
            width: 100,
            render: (menuType: keyof typeof menuTypes) => {
                return menuTypes[menuType] || '未知';
            }
        },
        {
            title: "请求地址",
            dataIndex: "frontRoute",
            align: "center",
            search: false,
            width: 200,
        },
        {
            title: "权限标识",
            dataIndex: "code",
            align: "center",
            search: false,
            width: 200,
        },
        {
            title: "所属应用",
            dataIndex: "applicationName",
            align: "center",
            width: 150,
        },
        {
            title: "所属应用",
            dataIndex: "tenantMeta",
            align: "center",
            width: 150,
            hideInTable: true,
            renderFormItem: () => {
                return <Select
                    allowClear
                    placeholder="请选择所属应用"
                    options={application?.map((e: any) => ({
                        value: e.tenantMeta,
                        label: e.name
                    }))}
                />
            },
        },
        {
            title: "关键词搜索",
            dataIndex: "keyword",
            align: "center",
            width: 150,
            search: true,
            hideInTable: true,
        },
        {
            title: "状态",
            dataIndex: "state",
            search: false,
            align: "center",
            width: 150,
            render: (_: any, item: any) => {
                return <Switch
                    checked={item.state}
                    size="small"
                    onChange={(checked) => handleEnableChange(item, checked)} />
            },
        },
        {
            title: "创建时间",
            dataIndex: "createTime",
            search: false,
            align: "center",
            width: 200,
        },
        {
            title: "操作",
            key: 'operation',
            search: false,
            align: "center",
            width: 150,
            render: (_: any, record: any) => (
                <>
                    <Button type='link' size='small' onClick={() => handleUpdateOrInsert(record)}>
                        编辑
                    </Button>
                    <Popconfirm title="确定删除？" okText="是" cancelText="否"
                        onConfirm={() => deleteHandle(record.id)}>
                        <Button type='link' size='small' danger>删除</Button>
                    </Popconfirm>
                </>
            ),
        }
    ];

    const handleEnableChange = (item: any, state: boolean) => {
        jsonRequest.post("/api/request", {
            path: `/api/v1/menu`,
            method: "post",
            application_key: "zy:services::user-library",
            tenant_meta: currentTenant,
            data: { ...item, state },
        }).then((resp: any) => resp.json())
            .then((data: any) => {
                // 未测试过实际成功失败返回
                if (data.code === 0) {
                    message.success(`${state ? "启用" : "禁用"}成功`);
                    tableRef.current?.reload();
                } else {
                    message.error(data.msg);
                }
            })
    }

    const deleteHandle = async (id: any) => {
        const result: any = await jsonRequest.post("/api/request", {
            path: `/api/v1/menu/${id}`,
            method: "delete",
            application_key: "zy:services::user-library",
            tenant_meta: currentTenant,
            data: {},
        });
        if (result?.body.code === 0)
            message.success('删除成功');
        else {
            message.error('删除失败');
            console.log(result);
        }
        tableRef.current?.reload();
    }

    const handleUpdateOrInsert = (item: any) => {
        Modal.confirm({
            width: 500,
            title: !!item.id ? `菜单编辑 - ${item.name}` : "菜单新增",
            content: <GlobalWrap><MenuForm form={form} item={item} tenantMeta={application} /></GlobalWrap>,
            onOk: async (close) => {
                let values = await form.validateFields();
                if (!!item.id) {
                    values.id = item.id;
                }
                jsonRequest.post("/api/request", {
                    path: `/api/v1/menu`,
                    method: "post",
                    application_key: "zy:services::user-library",
                    tenant_meta: currentTenant,
                    data: { ...values }
                })
                    .then((resp: any) => resp.json())
                    .then((data: any) => {
                        if (data.code === 0) {
                            if (data.data.code) {
                                message.error(data.data.message);
                            } else if (!!item.id) {
                                message.success("编辑成功");
                            } else {
                                message.success("新增成功");
                            }
                        } else {
                            message.error(data.msg);
                        }
                        tableRef.current?.reload();
                    }
                    )
                form.resetFields();
                close();
                return false;
            },
            onCancel: (close) => {
                form.resetFields();
                close();
            }
        })
    }

    const request = async (params: any, sort: any, filter: any) => {
        const result = await jsonRequest.post("/api/request", {
            path: "/api/v1/menu",
            method: "get",
            application_key: "zy:services::user-library",
            tenant_meta: currentTenant,
            data: {
                applicationKey: params.tenantMeta,
                ...params
            },
        }).then((resp: any) => resp.json());
        const { data } = result;
        return { data: data, success: result.code === 0 };
    };

    return <>
        <h1>菜单管理</h1>
        <ProTable
            rowKey="id"
            actionRef={tableRef}
            cardBordered
            columns={columns}
            request={request}
            pagination={{ defaultPageSize: 10, showSizeChanger: true }}
            search={{ span: 6, defaultCollapsed: false }}
            toolBarRender={() => [<Button
                key="button"
                icon={<PlusOutlined />}
                onClick={() => handleUpdateOrInsert({})}
                type="primary"
            >
                新增
            </Button>]}
        />
    </>;
}