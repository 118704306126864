import { ActionType, ProTable } from "@ant-design/pro-components";
import { Button, Flex, Form, List, message, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useGlobalState } from "../../components/global";
import { useEffect, useRef, useState } from "react";
import GlobalWrap from "../../components/globalWrap";
// import UserForm from "./userForm";
import UserRoleForm from "./userRoleForm";

export default function Library(params: any) {
    const { jsonRequest, currentTenant } = useGlobalState();
    const tableRef = useRef<ActionType>();
    const [authSource, setAuthSource]: any = useState([]);
    // const [form] = Form.useForm();
    const [roleForm] = Form.useForm();

    useEffect(() => {
        tableRef.current?.reload();
    }, [currentTenant]);

    useEffect(() => {
        getAuthSource();
    }, []);

    const getAuthSource = async () => {

        const { data } = await jsonRequest.post("/api/request", {
            path: "/api/v1/enum",
            method: "get",
            application_key: "zy:services::user-library",
            tenant_meta: currentTenant,
            data: { type: "source" },
        }).then((resp: any) => resp.json());

        setAuthSource(data);
    };

    const columns: any[] = [
        {
            title: "用户ID",
            dataIndex: "userId",
            align: "center",
            ellipsis: true,
            width: 200,
            copyable: true,
        },
        {
            title: "账户ID",
            dataIndex: "accountId",
            align: "center",
            ellipsis: true,
            width: 200,
            copyable: true,
        },
        {
            title: "名称",
            dataIndex: "name",
            align: "center",
        },
        {
            title: "手机号",
            dataIndex: "telephone",
            align: "center",
            search: false,
        },
        {
            title: "Data",
            dataIndex: "data",
            align: "center",
            search: false,
            render: (_: any, record: any) => <span>{JSON.stringify(record.data)}</span>,
        },
        {
            title: "创建时间",
            dataIndex: "createTime",
            search: false,
            align: "center",
            width: 200,
        },
        {
            title: "操作",
            key: 'operation',
            search: false,
            align: "center",
            width: 150,
            render: (_: any, record: any) => (
                <>
                    {/* <Button type='link' size='small' onClick={() => handleAddOrEdit(record)}>
                        编辑
                    </Button> */}
                    <Button type='link' size='small' onClick={() => distributeRoles(record)}>
                        分配角色
                    </Button>
                </>
            ),
        }
    ];

    // const handleAddOrEdit = (item: any) => {
    //     Modal.confirm({
    //         width: 500,
    //         title: !!item.userId ? `用户编辑 - ${item.name}` : "用户新增",
    //         content: <GlobalWrap><UserForm form={form} item={item} /></GlobalWrap>,
    //         onOk: async (close) => {
    //             let values = await form.validateFields();
    //             if (!!item.userId) {
    //                 values.userId = item.userId;
    //             }
    //             if (values.userId) {
    //                 jsonRequest.post(`/api/request`, {
    //                     path: "",
    //                     method: "post",
    //                     application_key: "zy:services::user-library",
    //                     tenant_meta: currentTenant,
    //                     data: {
    //                         ...values
    //                     }
    //                 })
    //                     .then((resp: any) => resp.json())
    //                     .then((data: any) => {
    //                         if (data.code === 0) {
    //                             if (data.data.code) {
    //                                 message.error(data.data.message);
    //                             } else {
    //                                 message.success("编辑成功");
    //                             }
    //                         } else {
    //                             message.error(data.msg);
    //                         }
    //                     }
    //                     )
    //             } else {
    //                 jsonRequest.post(`/api/request`, {
    //                     path: "",
    //                     method: "post",
    //                     application_key: "zy:services::user-library",
    //                     tenant_meta: currentTenant,
    //                     data: {
    //                         ...values
    //                     }
    //                 })
    //                     .then((resp: any) => resp.json())
    //                     .then((data: any) => {
    //                         if (data.code === 0) {
    //                             if (data.data.code) {
    //                                 message.error(data.data.message);
    //                             } else {
    //                                 message.success("新增成功");
    //                             }
    //                         } else {
    //                             message.error(data.msg);
    //                         }
    //                     }
    //                     )
    //             }
    //             console.log(values, 'val');
    //             form.resetFields();
    //             close();
    //             return false;
    //         },
    //         onCancel: (close) => {
    //             form.resetFields();
    //             close();
    //         }
    //     })
    // }

    const distributeRoles = (item: any) => {
        Modal.confirm({
            width: 500,
            title: "用户角色分配",
            content: <GlobalWrap><UserRoleForm form={roleForm} item={item} /></GlobalWrap>,
            onOk: async (close) => {
                let values = await roleForm.validateFields();
                values.accountId = item.accountId;
                jsonRequest.post(`/api/request`, {
                    path: "/api/v1/auth/user/role",
                    method: "post",
                    application_key: "zy:services::user-library",
                    tenant_meta: currentTenant,
                    data: {
                        ...values
                    }
                })
                    .then((resp: any) => resp.json())
                    .then((data: any) => {
                        if (data.code === 0) {
                            if (data.data.code) {
                                message.error(data.data.message);
                                tableRef.current?.reload();
                            } else {
                                message.success("编辑成功");
                                tableRef.current?.reload();
                            }
                        } else {
                            message.error(data.msg);
                        }
                    }
                    )
                roleForm.resetFields();
                close();
                return false;
            },
            onCancel: (close) => {
                roleForm.resetFields();
                close();
            }
        })
    }

    const request = async (params: any, sort: any, filter: any) => {
        const { current, pageSize } = params;
        const result = await jsonRequest.post("/api/request", {
            path: "/api/v1/auth/user",
            method: "get",
            application_key: "zy:services::user-library",
            tenant_meta: currentTenant,
            data: {
                ...params,
                pageNumber: current,
                pageSize,
            },
        }).then((resp: any) => resp.json());
        const { data } = result;
        return { data: data.list, success: result.code === 0, total: data.total };
    };

    return <>
        <h1>用户库</h1>
        <ProTable
            rowKey="userId"
            actionRef={tableRef}
            cardBordered
            columns={columns}
            expandable={{
                rowExpandable: (record: any) => record.authList?.length > 0,
                expandedRowRender: (record: any) =>
                    <List size="small"
                        dataSource={record.authList}
                        renderItem={(item: any) =>
                            <Flex justify="space-around">
                                <span>ID：{item.id}</span>
                                <span>授权来源：{authSource.find((i: any) => i.enumKey === item.source)?.enumName || item.source}</span>
                                <span>第三方标识：{item.identifier}</span>
                                <span>授权时间：{item.createTime}</span>
                            </Flex>
                        }
                    />,
            }}
            request={request}
            pagination={{ defaultPageSize: 10, showSizeChanger: true }}
            search={{ span: 6, defaultCollapsed: false }}
            toolBarRender={() => [<Button
                key="button"
                icon={<PlusOutlined />}
                // onClick={() => handleAddOrEdit({})}
                type="primary"
            >
                新增
            </Button>]}
        />
    </>;
}