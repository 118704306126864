import { useEffect, useState } from "react";
import { Progress, Tooltip, Button, Space, Flex, Popconfirm, Tag, message } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import "./PdfFileInfo.css";
import { bytesToSize } from "../../../../../components/utils";
import { useRequest } from "ahooks";
import { useGlobalState } from "../../../../../components/global";
import { showMdModal } from "./MdForm";
import OssPath from "../../../../../components/oss_path";

export default function PdfFileInfo({ file, onRemove, disabled }: any) {
    const { status, name, percent, size } = file;
    const { currentTenant, jsonRequest } = useGlobalState();
    const [hasMarkdown, setHasMarkdown]: any = useState(file.hasMarkdown);
    const [taskId, setTaskId]: any = useState(file.taskId);

    useEffect(() => {
        if (status === "done" && !hasMarkdown) {
            pdfToMd();
            file.hasMarkdown = true;
            setHasMarkdown(true);
        }
    }, [status]);

    // 将pdf转为markdown
    const pdfToMd = () => {
        jsonRequest.post(`/api/request`, {
            path: `/v2/ebook/toMd`,
            method: "post",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: { objectKey: file.originUrl, isOcr: false },
        })
            .then((resp: any) => resp.json())
            .then(({ data }: any) => {
                file.taskId = data?.taskId;
                setTaskId(data?.taskId);
                run();
            });
    };

    const getTaskInfo: any = () => {
        if (!taskId) return;
        return jsonRequest.post(`/api/request`, {
            path: `/v2/ebook/md/${taskId}`,
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {},
        })
            .then((resp: any) => resp.json())
            .then(({ data }: any) => {
                file.md = data;
                if (data?.state === "done") {
                    cancel();
                }
            });
    };

    const { run, cancel } = useRequest(getTaskInfo, {
        pollingInterval: 3000,
        manual: !taskId || (file?.md?.state === "done"),
    });

    const renderStatusIcon = () => {
        if (status === "done") {
            return <CheckCircleOutlined style={{ color: "#52c41a" }} />;
        } else if (status === "error") {
            return <CloseCircleOutlined style={{ color: "#ff4d4f" }} />;
        } else {
            return <Progress type="circle" percent={percent} size={14} />;
        }
    };

    const MdState = () => {
        const state = file?.md?.state;
        const progress = file?.md?.extractProgress;
        if (state === "done") {
            return <Tag color="green">生成完成</Tag>;
        } else if (state === "pending") {
            return <Tag color="orange">排队中</Tag>;
        } else if (state === "running") {
            return <Tag color="blue">正在解析{progress?.extracted_pages}/{progress?.total_pages}</Tag>;
        } else if (state === "failed") {
            return <Tag color="red">解析失败</Tag>;
        }
        return <Tag color="orange">生成中</Tag>;
    };

    const afterMarkdownEdited = async ({ content }: any) => {
        const fileName = file.md.mdUrl.split("objectKey=")[1];
        // 创建文件对象
        const blob = new Blob([content], { type: "text/markdown" });
        const md = new File([blob], fileName, { type: "text/markdown" });
        // 上传文件
        await updateMdFile(md);
        message.success("编辑成功");
    };

    const updateMdFile = async (file: any) => {
        const { data } = await jsonRequest.post("/api/request", {
            path: `/v1/aliyun/oss`,
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: { type: "ebook" },
        })
            .then((resp: any) => resp.json());

        const ossPath = await OssPath(jsonRequest, currentTenant);
        var formData = new FormData();
        formData.append("key", file.name);
        formData.append("policy", data.policy);
        formData.append("OSSAccessKeyId", data.keyId);
        formData.append("Signature", data.signature);
        formData.append("success_action_status", "200");
        formData.append("file", file);

        await fetch(data.host, { method: "POST", body: formData, mode: "cors" });
        return `${ossPath}${file.url}`;
    };

    return (
        <Flex align={"center"} justify={"space-between"} className="pdf-file-item">
            <Space>
                {renderStatusIcon()}
                <Tooltip title={name}>
                    <Button type="link" onClick={() => window.open(file.url)}
                            style={{ padding: 0 }}>
                        <span className="pdf-file-name">{name} </span>
                    </Button>
                </Tooltip>
                <span>{bytesToSize(size)}</span>
            </Space>
            {status === "done" && <Space>
                <MdState />
                <Button size="small" type="link" disabled={file.md?.state !== "done" || disabled}
                        onClick={() => showMdModal({
                            mdUrl: file.md.mdUrl,
                            pdfUrl: file.url,
                            onOk: afterMarkdownEdited,
                            isOrigin: true,
                            showAi: true,
                        })}>编辑</Button>
                <Popconfirm title="确认删除吗？" onConfirm={onRemove}>
                    <Button size="small" type="link" danger disabled={disabled}>删除</Button>
                </Popconfirm>
            </Space>}
        </Flex>
    );
};
