import { Checkbox, Form, Input, Radio } from "antd";
import { useEffect, useState } from "react";
import { useGlobalState } from "../../components/global";

export default function UserRoleForm({ form, item }: any) {
    const { jsonRequest, currentTenant } = useGlobalState();
    const [roleData, setRoleData] = useState<any>();

    useEffect(() => {
        getRoleData();
        form.setFieldsValue(item);
    }, []);

    const getRoleData = async () => {
        // 获取角色数据
        jsonRequest.post("/api/request",
            {
                path: `/api/v1/role`,
                method: "get",
                application_key: "zy:services::user-library",
                tenant_meta: currentTenant,
                data: {},
            })
            .then((resp: any) => resp.json())
            .then(async ({ data }: any) => {
                setRoleData(data);
            });
    };

    return (
        <Form form={form} size="small" name="basic" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} autoComplete="off">
            <Form.Item label="用户名称" name="name" rules={[{ required: true, message: '不能为空' }]}>
                <Input placeholder="请输入用户名称" disabled />
            </Form.Item>
            <Form.Item label="角色" name="roleIds">
                <Checkbox.Group
                    options={roleData?.map((e: any) => ({ value: e.id, label: e.name }))}
                />
            </Form.Item>
        </Form>
    )
}