import { Button, Form, Input, message, Modal, Popconfirm, Select, Switch } from "antd";
import { useGlobalState } from "../../components/global";
import { useEffect, useRef, useState } from "react";
import GlobalWrap from "../../components/globalWrap";
import RoleForm from "./roleForm";
import { ActionType, ProTable } from "@ant-design/pro-components";
import { PlusOutlined } from "@ant-design/icons";

export default function Role(params: any) {
    const { jsonRequest, currentTenant } = useGlobalState();
    const tableRef = useRef<ActionType>();
    const [tenantMeta, setTenantMeta] = useState<any>();
    const [ownerTenantMeta, setOwnerTenantMeta] = useState<any>();
    const [keyword, setKeyword] = useState<any>('');
    const [form] = Form.useForm();

    useEffect(() => {
        getTenantData();
        tableRef.current?.reload();
    }, [currentTenant]);

    const deleteHandle = async (id: any) => {
        const result: any = await jsonRequest.post("/api/request", {
            path: `/api/v1/role/${id}`,
            method: "delete",
            application_key: "zy:services::user-library",
            tenant_meta: currentTenant,
            data: {},
        });
        if (result?.body.code === 0)
            message.success('删除成功');
        else {
            message.error('删除失败');
            console.log(result);
        }
        tableRef.current?.reload();
    }

    const handleUpdateOrInsert = (item: any) => {
        Modal.confirm({
            width: 500,
            title: !!item.id ? `角色编辑 - ${item.name}` : "角色新增",
            content: <GlobalWrap><RoleForm form={form} item={item} tenantMeta={tenantMeta} /></GlobalWrap>,
            onOk: async (close) => {
                let values = await form.validateFields();
                if (!!item.id) {
                    values.id = item.id;
                }
                jsonRequest.post("/api/request", {
                    path: `/api/v1/role`,
                    method: "post",
                    application_key: "zy:services::user-library",
                    tenant_meta: currentTenant,
                    data: { ...values }
                })
                    .then((resp: any) => resp.json())
                    .then((data: any) => {
                        if (data.code === 0) {
                            if (data.data.code) {
                                message.error(data.data.message);
                            } else if (!!item.id) {
                                message.success("编辑成功");
                                tableRef.current?.reload();
                            } else {
                                message.success("新增成功");
                                tableRef.current?.reload();
                            }
                        } else {
                            message.error(data.msg);
                        }
                    }
                    )
                form.resetFields();
                close();
                return false;
            },
            onCancel: (close) => {
                form.resetFields();
                close();
            }
        })
    }

    const columns: any[] = [
        {
            title: "排序号",
            dataIndex: "index",
            search: false,
            align: "center",
            width: 100,
        },
        {
            title: "角色ID",
            dataIndex: "id",
            align: "center",
            ellipsis: true,
            search: false,
            width: 200,
        },
        {
            title: "角色名称",
            dataIndex: "name",
            search: false,
            align: "center",
            width: 150,
        },
        {
            title: "权限字符串",
            dataIndex: "code",
            search: false,
            align: "center",
            width: 200,
        },
        {
            title: "所属租户",
            dataIndex: "tenantName",
            search: false,
            align: "center",
            width: 100,
        },
        {
            title: "所属租户",
            dataIndex: "tenantMeta",
            align: "center",
            width: 150,
            hidden: true,
            renderFormItem: () => <Select
                allowClear
                options={tenantMeta?.map((e: any) => ({
                    value: e.tenantMeta,
                    label: e.name
                }))}
                placeholder="请选择租户"
                value={ownerTenantMeta}
                onChange={setOwnerTenantMeta}
            />,
        },
        {
            title: "关键词搜索",
            dataIndex: "keyword",
            align: "center",
            width: 150,
            hidden: true,
            renderFormItem: () =>
                <Input
                    allowClear
                    value={keyword}
                    placeholder="请输入关键词(模糊搜索)"
                    onChange={(e) => setKeyword(e.target.value)}
                />,
        },
        {
            title: "角色状态",
            dataIndex: "state",
            search: false,
            align: "center",
            width: 100,
            render: (_: any, item: any) => {
                return <Switch
                    checked={item.state}
                    size="small"
                    onChange={(checked) => handleEnableChange(item, checked)} />
            },
        },
        {
            title: "创建时间",
            dataIndex: "createTime",
            search: false,
            align: "center",
            width: 200,
        },
        {
            title: "操作",
            key: 'operation',
            search: false,
            align: "center",
            width: 150,
            render: (_: any, record: any) => (
                <>
                    <Button type='link' size='small' onClick={() => handleUpdateOrInsert(record)}>
                        编辑
                    </Button>
                    <Popconfirm title="确定删除？" okText="是" cancelText="否"
                        onConfirm={() => deleteHandle(record.id)}>
                        <Button type='link' size='small' danger>删除</Button>
                    </Popconfirm>
                </>
            ),
        }
    ];

    const getTenantData = async () => {
        // 获取租户下拉数据
        jsonRequest.post("/api/request",
            {
                path: `/api/v1/role/tenant`,
                method: "get",
                application_key: "zy:services::user-library",
                tenant_meta: currentTenant,
                data: {},
            })
            .then((resp: any) => resp.json())
            .then(async ({ data }: any) => {
                setTenantMeta(data);
            });
    };

    const request = async (params: any, sort: any, filter: any) => {
        const result = await jsonRequest.post("/api/request", {
            path: "/api/v1/role/paged",
            method: "get",
            application_key: "zy:services::user-library",
            tenant_meta: currentTenant,
            data: {
                keyword,
                ownerTenantMeta,
                pageNumber: params.current,
                pageSize: params.pageSize
            },
        }).then((resp: any) => resp.json());
        const { data } = result;
        return { data: data.list, success: result.code === 0, total: data.total };
    };

    const handleEnableChange = (item: any, state: boolean) => {
        jsonRequest.post("/api/request", {
            path: `/api/v1/role`,
            method: "post",
            application_key: "zy:services::user-library",
            tenant_meta: currentTenant,
            data: { ...item, state },
        }).then((resp: any) => resp.json())
            .then((data: any) => {
                if (data.code === 0) {
                    message.success(`${state ? "启用" : "禁用"}成功`);
                    tableRef.current?.reload();
                } else {
                    message.error(data.msg);
                }
            })
    }

    return <>
        <h1>角色管理</h1>
        <ProTable
            rowKey="id"
            actionRef={tableRef}
            cardBordered
            columns={columns}
            request={request}
            search={{ span: 6, defaultCollapsed: false }}
            toolBarRender={() => [<Button
                key="button"
                icon={<PlusOutlined />}
                onClick={() => handleUpdateOrInsert({})}
                type="primary"
            >
                新增
            </Button>]}
        />
    </>;
}