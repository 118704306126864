import KnowledgeFoundationUploader from "../../../../components/KnowledgeFoundationUploader";
import { useGlobalState } from "../../../../../components/global";
import { MdEditor } from "md-editor-rt";
import "md-editor-rt/lib/style.css";
import { useEffect, useRef } from "react";
import "./CustomMdEditor.css";

export default function CustomMdEditor({ onChange, value, hideMd, ...props }: any) {
    const { jsonRequest, currentTenant } = useGlobalState();
    const ref: any = useRef();

    useEffect(() => {
        ref.current?.toggleCatalog(true);
        if (hideMd)
            ref.current?.togglePreviewOnly(true);
    }, []);

    const onImageUpload = async (files: any, callback: any) => {
        const res = await Promise.all(
            files.map(async (file: any) => await KnowledgeFoundationUploader(jsonRequest, currentTenant, file)),
        );
        callback(res);
    };

    return <MdEditor className="custom-md-editor"
                     ref={ref}
                     modelValue={value}
                     onChange={onChange}
                     onUploadImg={onImageUpload}
                     toggleCatalog={true} {...props} />;
}