import { Menu } from 'antd';
import { NodeExpandOutlined } from '@ant-design/icons';
import { useLocation, Link } from "react-router-dom";

function SideMenu() {
    const location = useLocation();
    const pathName = location.pathname.split("/").slice(0, 3).join("/");
    const fullPathName = location.pathname;

    return <Menu style={{ width: 256 }} defaultSelectedKeys={[pathName, fullPathName]} mode="inline" >
        <Menu.ItemGroup key="cooperation" title="商务合作">
            <Menu.Item key="/cooperation/collaborators" icon={<NodeExpandOutlined />}>
                <Link to="/cooperation/collaborators">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>合作招募</span>
                    </div>
                </Link>
            </Menu.Item>
        </Menu.ItemGroup>
    </Menu >
}

export default SideMenu;