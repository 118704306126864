import { Form, Modal, Button, Flex, Space, Input, message, Spin } from "antd";
import GlobalWrap from "../../../../../components/globalWrap";
import { useEffect, useState } from "react";
import CustomMdEditor from "./CustomMdEditor";
import { useGlobalState } from "../../../../../components/global";


function MdForm({ origin, onOk, onCancel }: any) {
    const [form] = Form.useForm();
    const { mdUrl, pdfUrl, hideMd, showAi, content, title, parent, level, isCatalogue, isOrigin, fullMdUrl } = origin;
    const [aiLoading, setAiLoading] = useState(false);
    const { currentTenant, jsonRequest } = useGlobalState();

    // 假设 mdUrl 是一个 URL，我们可以通过 fetch 获取 Markdown 内容
    useEffect(() => {
        if (!mdUrl) return;
        fetch(mdUrl, {
            cache: "no-store", // 禁用浏览器缓存
        })
            .then(response => response.text())
            .then(text => {
                form.setFieldsValue({ content: text });
            })
            .catch(error => console.error("Failed to fetch markdown content:", error));
    }, []);

    const onSave = async () => {
        try {
            const values = await form.validateFields();
            onOk && onOk(values);
        } catch (e) {
        }
    };

    const catalogueAi = async () => {
        setAiLoading(true);
        message.info("请稍后");
        const { data } = await jsonRequest.post("/api/request", {
            path: `/v1/ebook/md/catalogue/ai`,
            method: "post",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {
                catalogue: form.getFieldValue("content"),
                mdUrl: fullMdUrl,
            },
        })
            .then((resp: any) => resp.json());
        form.setFieldValue("content", data?.catalogue);
        message.success("AI优化完成");
        setAiLoading(false);
    };

    const sectionAi = async () => {
        setAiLoading(true);
        message.info("请稍后");
        const { data } = await jsonRequest.post("/api/request", {
            path: `/v1/ebook/md/section/ai`,
            method: "post",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {
                level,
                content: form.getFieldValue("content"),
            },
        })
            .then((resp: any) => resp.json());
        form.setFieldValue("content", data[0]?.content);
        message.success("AI优化完成");
        setAiLoading(false);
    };

    const mdAi = async () => {
        setAiLoading(true);
        message.info("请稍后");
        const { data } = await jsonRequest.post("/api/request", {
            path: `/v1/ebook/md/ai`,
            method: "post",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {
                mdUrl: mdUrl,
                mdContent: form.getFieldValue("content"),
            },
        })
            .then((resp: any) => resp.json());
        form.setFieldValue("content", data?.content);
        message.success("AI优化完成");
        setAiLoading(false);
    };

    return <>
        <Form form={form}>
            <Form.Item label="文件" hidden={!pdfUrl} labelCol={{ span: 2 }} wrapperCol={{ span: 19 }}>
                <Button type="link">{pdfUrl}</Button>
            </Form.Item>
            <Form.Item label="上级" hidden={!parent}
                       labelCol={{ span: 2 }} wrapperCol={{ span: 19 }}>
                <Button type="link">{parent?.title ?? "无"}</Button>
            </Form.Item>
            {(title || parent) &&
                <Form.Item label={`标题${level ? `（层级:${level}）` : ""}`} name="title" initialValue={title}
                           labelCol={{ span: 2 }} wrapperCol={{ span: 19 }}
                           rules={[{ required: true, message: "不能为空" }]}>
                    <Input />
                </Form.Item>}
            <Spin spinning={aiLoading}>
                <Form.Item name="content" initialValue={content ?? ""}>
                    <CustomMdEditor style={{ height: "70vh" }} hideMd={hideMd} />
                </Form.Item>
            </Spin>
        </Form>
        <Flex justify="end" style={{ margin: "20px 20px 0" }}>
            <Space>
                <Button onClick={onCancel}>取消</Button>
                {showAi && <Button loading={aiLoading}
                                   onClick={isCatalogue ? catalogueAi : isOrigin ? mdAi : sectionAi}>{isCatalogue ? "AI正文提目录" : isOrigin ? "AI排版优化" : "AI章节优化"}</Button>}
                {!hideMd && <Button type="primary" onClick={onSave}>保存</Button>}
            </Space>
        </Flex>
    </>;
}

const showMdModal = ({ onOk, ...others }: any) => {
    let modal: any;
    const afterFormSubmit = async (values: any) => {
        onOk && await onOk(values);
        modal.destroy();
    };
    modal = Modal.confirm({
        width: "80vw",
        title: `Markdown${others.hideMd ? "预览" : "编辑"}`,
        keyboard: false,
        content: <GlobalWrap>
            <MdForm origin={others} onCancel={() => modal.destroy()} onOk={afterFormSubmit} />
        </GlobalWrap>,
        footer: null,
    });
};

export { showMdModal };